<template>
  <div class="announcement-details">
    <van-nav-bar
      left-arrow
      title="通知详情"
      fixed
      @click-left="$router.back()"
    />
    <div
      :class="[
        'wrapper',
        hasBtn
          ? 'wrapper-height__feature-btn'
          : 'wrapper-height__not-feature-btn'
      ]"
    >
      <div class="other">
        <p>{{ info.name }}</p>
        <span>{{ info.utime }}</span>
      </div>
      <div class="content" v-html="info.content" />
      <div v-if="hasBtn" class="details-page__footer-button-wrapper--fixed">
        <van-button block round type="info" @click="completeNotice"
          >完成</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  completeNotices,
  getInfoNotices,
  getNoticesBtnStatus
} from "@/api/psm";
import { operateMessage } from "@/utils";

export default {
  name: "AnnounceDetails",
  props: ["id"],
  data() {
    return {
      hasBtn: false,
      info: {},
      completeParams: { id: "", userId: "" }
    };
  },
  created() {
    this.completeParams.id = this.id;
    this.completeParams.userId = this.userInfo.id;
    this.getInfo();
  },
  methods: {
    async getInfo() {
      this.info = await getInfoNotices(this.id);
      this.hasBtn = await getNoticesBtnStatus(this.completeParams);
    },
    async completeNotice() {
      const r = await completeNotices(this.completeParams);
      operateMessage(!!r, "操作");
      this.getInfo();
    }
  }
};
</script>

<style lang="scss">
.announcement-details {
  padding-top: 58px;
  .wrapper {
    overflow: auto;
    padding: 16px;
    box-sizing: border-box;
    .other {
      position: relative;
      padding-bottom: 36px;
      border-bottom: 1px solid #e1e3e8;
      margin-bottom: 16px;
      p {
        font-size: 16px;
        font-weight: 500;
        color: #3b4664;
        line-height: 24px;
        font-family: PingFangSC-Medium, PingFang SC, serif;
      }
      span {
        position: absolute;
        right: 0;
        width: 100%;
        text-align: right;
        bottom: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #aeb3c0;
        line-height: 12px;
      }
    }
    .content {
      color: #3b4664;
      line-height: 22px;
      font-size: 14px;
      p {
        margin-bottom: 8px;
      }
      img {
        max-width: 100%;
      }
      & > * {
        word-break: break-word;
      }
      table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        height: 100%;
        border: 1px double #e1e3e8;
      }
      table td,
      table th {
        min-width: 2em;
        padding: 0.4em;
        border: 1px solid #e1e3e8;
        vertical-align: middle;
        color: #99a3af;
      }
      table th {
        font-weight: bold;
        background: hsla(0, 0%, 0%, 5%);
      }
    }
    .details-page__footer-button-wrapper--fixed {
      margin: 0 -16px;
    }
  }
}
</style>
